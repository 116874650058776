import { message, Modal, notification } from 'antd';
import { furthest, RGBColor } from 'color-diff';
import { i18n } from 'i18next';
import { IAppSetting, Language } from '../interfaces';
import {
  ApolloCache,
  ApolloError,
  DefaultContext,
  gql,
  MutationUpdaterFunction,
  OperationVariables,
} from '@apollo/client';
import { ApolloContextKey } from '../enums/ApolloContextKey';
import {
  queryRequireTenantId,
  updateCacheHandlerByName,
} from '../services/graphql/cache';
import { Query } from '../services/graphql/query';
import { parse, stringify } from 'flatted';
import { EnumsValues, LocalStorageKeys } from '../enums/EnumsValues';
import { TrackedQuery } from '../hooks/apollo/ApolloProviderWrapper';
import { PDFDocument } from 'pdf-lib';
export class InternalError extends Error {
  isInternal: true;

  constructor(message: string) {
    super(message);
    this.isInternal = true;
  }
}

export const MAX_SIZE_IMAGE_IN_MB = 10;
export const PREFIX_LEGAL_NUMBER = 20110;
export const SECONDS_CONSTANT = 1000;

const Tools = () => {};

const MESSAGE_CREATE_SUCCESS = 'Creado exitosamente';
const MESSAGE_CREATE_ERROR = 'Error al crear, intente nuevamente!';
const MESSAGE_UPDATE_SUCCESS = 'Actualizado exitosamente';
const MESSAGE_UPDATE_ERROR = 'Error al actualizar, intente nuevamente!';
const MESSAGE_DELETE_SUCCESS = 'Eliminado exitosamente';
const MESSAGE_DELETE_ERROR = 'Error al eliminar, intente nuevamente!';

const DEFAULT_TIMEOUT_LOADING = 150000;
const DEFAULT_TIMEOUT_MESSAGE = 1200;

/**
 * Iniciar un loading
 * @param msg
 */
Tools.messageLoading = (msg?: string, time?: number) => {
  message.destroy();
  message.loading(msg || 'Cargando...', time || DEFAULT_TIMEOUT_LOADING);
};

Tools.messageDestroy = (time?: number, callback?: Function) => {
  setTimeout(() => {
    message.destroy();
    if (callback) {
      callback();
    }
  }, time || DEFAULT_TIMEOUT_MESSAGE);
};

Tools.messageModalSuccess = (msg: string | JSX.Element) => {
  message.destroy();
  Modal.success({
    content: msg,
  });
};

Tools.messageModalError = (msg: string | JSX.Element) => {
  message.destroy();
  Modal.error({
    content: msg,
  });
};

Tools.messageModalInfo = (msg: string | JSX.Element) => {
  message.destroy();
  Modal.info({
    content: msg,
  });
};

/**
 * Resultado de un proceso de exito
 * @param msg
 */
Tools.messageSuccess = (msg: string, time?: number) => {
  message.destroy();
  message.success(msg);
  Tools.messageDestroy(time);
};

/**
 * Resultado de un proceso que dio error
 * @param msg
 */
Tools.messageError = (msg: string, time?: number) => {
  message.destroy();
  message.error(msg);
  Tools.messageDestroy(time);
};

/**
 * Para acciones de ABM
 */
Tools.messageCreating = (msg: string) => {
  Tools.messageLoading(`Creando ${msg} ...`);
};
Tools.messageUpdating = (msg: string) => {
  Tools.messageLoading(`Actualizando ${msg} ...`);
};
Tools.messageDeleting = (msg: string) => {
  Tools.messageLoading(`Eliminando ${msg} ...`);
};

/**
 * Para resultados de ABM
 */
Tools.messageCreateSuccess = () => {
  Tools.messageSuccess(MESSAGE_CREATE_SUCCESS);
};
Tools.messageCreateError = () => {
  Tools.messageError(MESSAGE_CREATE_ERROR);
};
Tools.messageUpdateSuccess = () => {
  Tools.messageSuccess(MESSAGE_UPDATE_SUCCESS);
};
Tools.messageUpdateError = () => {
  Tools.messageError(MESSAGE_UPDATE_ERROR);
};
Tools.messageDeleteSuccess = () => {
  Tools.messageSuccess(MESSAGE_DELETE_SUCCESS);
};
Tools.messageDeleteError = () => {
  Tools.messageError(MESSAGE_DELETE_ERROR);
};

/**
 * Set currency
 * TODO: system_settting for currency and locale and minimumFractionDigits
 */
Tools.formatCurrency = (val: any): string => {
  return new Intl.NumberFormat('es-AR', {
    minimumFractionDigits: 0,
    style: 'currency',
    currency: 'ARS',
  }).format(Number(val));
};

/**
 * Traduce el valor de ordenamiento de TablePro al valor que necesita el backend
 * @param data
 */
Tools.getTypeOrderByTableSortParam = (val: string) => {
  return val === 'ascend' ? 'asc' : 'desc';
};

/**
 * Retorna ArrayBuffer de un base64
 * @param base64
 */
Tools.base64ToArrayBuffer = (base64: string) => {
  const binaryString = window.atob(base64); // Comment this if not using base64
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((_byte, i) => binaryString.charCodeAt(i));
};

/**
 * Retorna URL de un Blob
 * @param file
 */
Tools.createObjectURLOfBlob = (file: any) => {
  const arrayBuffer = Tools.base64ToArrayBuffer(file.img);
  const blob = new Blob([arrayBuffer], { type: file.mimetype });
  const fileURL: string = URL.createObjectURL(blob);
  return fileURL;
};

/**
 * Descarga archivo PDF
 * @param file
 */
Tools.downloadFile = (file: any) => {
  const createAndDownloadBlobFile = (arrayBuffer: any, filename: string) => {
    const blob = new Blob([arrayBuffer]);
    const fileName = `${filename}`;
    if ((navigator as any).msSaveBlob) {
      // IE 10+
      (navigator as any).msSaveBlob(blob, fileName);
    } else {
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      // eslint-disable-next-line no-useless-escape
      link.download = file.filename.replace(/^.*[\\\/]/, '');
      link.click();
      window.URL.revokeObjectURL(url);
    }
  };

  const arrayBuffer = Tools.base64ToArrayBuffer(file.file);
  createAndDownloadBlobFile(arrayBuffer, file.filename);
};

/**
 * Descarga archivo de imagen (png - jpeg - gif)
 * @param image
 */
Tools.downloadImage = (image: any) => {
  const link = document.createElement('a');
  link.href = `data:${image.mimetype};base64,${image.img}`;
  link.download = image.filename;
  link.click();
};

Tools.filter = (list: any[], search: string) => {
  if (search) {
    const result: any[] = list.filter((item) => {
      let itemLower = JSON.stringify(item);
      itemLower = itemLower.toLowerCase();
      const searchSplit: any[] = search.toLowerCase().split(' ');
      let validate = 0;
      searchSplit.forEach((search) => {
        if (itemLower.indexOf(search) !== -1) {
          validate += 1;
        }
      });
      if (validate === searchSplit.length) {
        return true;
      } else {
        return false;
      }
    });
    return result;
  } else {
    return list;
  }
};

Tools.fileWeightOfSize = (size: number): number => {
  //lo paso a kbyte
  const kbyte = size / 1000;
  //lo paso a megas
  const mega = kbyte / 1000;
  //redondeo a un decimal
  return Number(mega.toFixed(2));
};

Tools.capitalize = (text: string): string => {
  if (text.length > 1) {
    const firstCharacter = text.charAt(0);
    return `${firstCharacter.toUpperCase()}${text.substring(1)}`;
  }
  return text;
};

Tools.stripPaginationVariables = (variables: any) => {
  const returningVariables = { ...variables };
  delete returningVariables.skip;
  delete returningVariables.take;
  return returningVariables;
};

Tools.getSkip = (pageSize: number | undefined, current: number | undefined) => {
  if (!pageSize || !current) {
    return 0;
  }
  return pageSize * (current - 1);
};

Tools.blobToUrlBase64 = (blob: Blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

Tools.sortByZone = (a: any, b: any) => {
  let [ahh, amm] = a.label.split('GMT')[1].split(')')[0].split(':');
  let [bhh, bmm] = b.label.split('GMT')[1].split(')')[0].split(':');
  return +ahh * 60 + amm - (+bhh * 60 + bmm);
};
Tools.isDefined = (value: any) => value !== null && value !== undefined;

Tools.getBase64WithCallback = (img: any, callback: any) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

interface IGetUrlOfBase64FileParameter {
  mimetype: string;
  fileBase64: string;
}

Tools.getUrlOfBase64File = (fileData: IGetUrlOfBase64FileParameter): string => {
  return `data:${fileData.mimetype};base64,${fileData.fileBase64}`;
};

Tools.hexToRgb = (hexColor: string): RGBColor => {
  const hex = hexColor.charAt(0) === '#' ? hexColor.substring(1, 7) : hexColor;
  return {
    R: parseInt(hex.slice(0, 2), 16),
    G: parseInt(hex.slice(2, 4), 16),
    B: parseInt(hex.slice(4, 6), 16),
  };
};

Tools.rgbToHex = (rgbColor: RGBColor): string => {
  const { R, G, B } = rgbColor;
  return `#${[R, G, B]
    .map((value) => value.toString(16).padStart(2, '0'))
    .join('')}`;
};

Tools.getFontColorWithContrast = (
  bgColor: string,
  lightColor: string = '#ffffff',
  darkColor: string = '#000000',
): string => {
  const color = Tools.hexToRgb(bgColor);
  const palette = [Tools.hexToRgb(lightColor), Tools.hexToRgb(darkColor)];
  const bestMatch = furthest(color, palette);
  return Tools.rgbToHex(bestMatch);
};

Tools.getSettingDescriptionTranslation = (
  setting: IAppSetting,
  i18n: i18n,
  languages: Language[],
) => {
  const language_id = languages.find(
    (lang) => lang.language_code === i18n.language,
  )?.id;
  if (!language_id) return setting.description || '';

  const translation = setting.app_setting_translation?.find(
    (translation) => translation.language_id === language_id,
  )?.description;

  return translation || setting.description || '';
};

Tools.insertElementInSpecificPositionOfArray = <T,>(data: {
  array: T[];
  element: T;
  position: number;
}): T[] => {
  const { array = [], element } = data;
  let position = data.position;

  if (position < 0) position = 0;
  if (position > array.length) position = array.length;

  return array.slice(0, position).concat(element).concat(array.slice(position));
};

Tools.base64ToBlob = (
  base64Data: string,
  contentType: string = 'application/pdf',
): Blob => {
  const byteCharacters = window.atob(base64Data);
  const byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

const base64ToUint8Array = (base64Data: string): Uint8Array => {
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  return new Uint8Array(byteNumbers);
};

const handlePrintBlob = (pdfBlob: Blob) => {
  const pdfURL = URL.createObjectURL(pdfBlob); // Creamos una URL a partir del Blob
  const iframe = document.createElement('iframe'); // Creamos un iframe temporal
  iframe.style.display = 'none'; // Ocultamos el iframe
  iframe.src = pdfURL;

  // Una vez que el iframe se haya cargado, imprimimos el contenido
  iframe.onload = () => {
    if (iframe.contentWindow) {
      iframe.contentWindow.focus();
      iframe.contentWindow.print();
    }
  };

  document.body.appendChild(iframe);
};
const combinePdfs = async (pdfBase64Array: string[]): Promise<Blob> => {
  const mergedPdf = await PDFDocument.create();

  for (const pdfBase64 of pdfBase64Array) {
    const existingPdfBytes = base64ToUint8Array(pdfBase64);
    const existingPdf = await PDFDocument.load(existingPdfBytes);
    const copiedPages = await mergedPdf.copyPages(
      existingPdf,
      existingPdf.getPageIndices(),
    );

    copiedPages.forEach((page) => {
      mergedPdf.addPage(page);
    });
  }

  const mergedPdfBytes = await mergedPdf.save();
  return new Blob([mergedPdfBytes], { type: 'application/pdf' });
};

Tools.handlePrintMultiplePdfAllAsOne = async (pdfBase64Array: string[]) => {
  const mergedPdfBlob = await combinePdfs(pdfBase64Array); // Combinamos los PDFs
  handlePrintBlob(mergedPdfBlob); // Imprimimos el archivo combinado
};
interface LocalStorageSchema {
  [LocalStorageKeys.optimisticValuesToReplace]: (
    | [number, number]
    | [string, string]
  )[];
  [LocalStorageKeys.TrackedQueries]: TrackedQuery[];
  [LocalStorageKeys.Token]: string;
  [LocalStorageKeys.UserId]: number;
  [LocalStorageKeys.TwoFactorValidated]: boolean;
  [LocalStorageKeys.TwoFAOmitted]: string;
  [LocalStorageKeys.LastTenantId]: string;
  [LocalStorageKeys.googleImage]: string;
}

/**
 * To define whether an object is plain or has circular references
 */
const isPlain: { [key in keyof LocalStorageSchema]: boolean } = {
  [LocalStorageKeys.optimisticValuesToReplace]: true,
  [LocalStorageKeys.TrackedQueries]: false,
  [LocalStorageKeys.Token]: true,
  [LocalStorageKeys.UserId]: true,
  [LocalStorageKeys.TwoFactorValidated]: true,
  [LocalStorageKeys.TwoFAOmitted]: true,
  [LocalStorageKeys.LastTenantId]: true,
  [LocalStorageKeys.googleImage]: true,
};

/**
 * Función de escritura tipada del local storage
 */
Tools.setStorage = <T extends keyof LocalStorageSchema>(
  key: T,
  value: LocalStorageSchema[T],
): void => {
  if (isPlain[key]) {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.setItem(key, stringify(value));
  }
};

/**
 * Función de lectura tipada del local storage
 */
Tools.getStorage = <T extends keyof LocalStorageSchema>(
  key: T,
): LocalStorageSchema[T] | null => {
  const value = localStorage.getItem(key);
  if (!value) return null;
  if (isPlain[key]) {
    return JSON.parse(value) as LocalStorageSchema[T];
  } else {
    return parse(value) as LocalStorageSchema[T];
  }
};

/**
 * Reemplaza dentro de un objeto todas las ocurrencias de un determinado valor por otro. Ambos deben ser del mismo tipo
 */
Tools.replaceValuesInObj = <T extends string | number>(
  o: Record<string, any>,
  targetValue: T,
  replaceValue: T,
) => {
  Object.keys(o).forEach((key) => {
    if (o[key] === targetValue) o[key] = replaceValue;
    if (typeof o[key] === 'object' && o[key] !== null) {
      Tools.replaceValuesInObj(o[key], targetValue, replaceValue);
    }
  });
};

Tools.isGraphqlError = (
  error: Error & { status_code?: number; statusCode?: number },
) => {
  return error?.status_code !== undefined || error?.statusCode !== undefined;
};

/**
 * Generador de contexto estándar de una mutación trackeada
 */
Tools.trackedContext = (
  serializationKey: (string | number)[],
  requestName: string,
) => ({
  tracked: true,
  serializationKey: serializationKey.map((v) => v.toString()),
  [ApolloContextKey.CUSTOM_OPERATION_NAME]: requestName,
});

/**
 * Actualiza la cache en base a las funciones definidas para cada mutacion, y su rollback en caso de error
 */
const updateCache: MutationUpdaterFunction<
  any,
  OperationVariables,
  DefaultContext,
  ApolloCache<any>
> = (cache, { data: mutationData }, { context, variables }) => {
  if (!mutationData) return;

  const operationName = context?.[ApolloContextKey.CUSTOM_OPERATION_NAME];

  if (mutationData[operationName].isOptimistic && context?.rerun) return;

  if (operationName in updateCacheHandlerByName) {
    // Loop through cache updates of this mutation
    for (const [query, func] of Object.entries(
      updateCacheHandlerByName[
        operationName as keyof typeof updateCacheHandlerByName
      ],
    )) {
      if (query in Query) {
        const tenantId = queryRequireTenantId.includes(query)
          ? variables?.tenant_id || variables?.input?.tenant_id
          : undefined;

        const gqlQuery = gql(Query[query as keyof typeof Query].gql);
        try {
          cache.updateQuery(
            {
              query: gqlQuery,
              variables: tenantId ? { tenant_id: tenantId } : undefined,
            },
            (data: any) => {
              const result = func(data, {
                mutationResult: { data: mutationData },
              });
              return result;
            },
          );
        } catch (error) {
          console.error(error);
        }
      }
    }
  }
};

Tools.updateCache = updateCache;

Tools.getOptimisticResponse = (context: DefaultContext) => {
  return context.optimisticResponse ?? context._optimisticResponse;
};

Tools.getRandomNumber = () => {
  return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
};

Tools.processQueryError = (error: InternalError | Error | ApolloError) => {
  if (
    error instanceof InternalError ||
    (error instanceof ApolloError &&
      error.networkError instanceof InternalError)
  ) {
    console.error(error);
  } else {
    notification.error({
      message: error.message,
    });
  }
};

Tools.priceNumberFormatter = (value?: string) => {
  if (!value) return '';
  const stringValue = value.toString();
  const parts = stringValue.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return `$${parts.join(',')}`;
};

Tools.priceNumberParser = (value?: string) => {
  if (!value) return '';
  // Elimina el signo "$", todos los puntos de los miles y reemplaza la coma decimal con un punto
  const replacedValue = value
    .replace(/\$/g, '')
    .replace(/\./g, '')
    .replace(',', '.');
  // Parsea el valor numérico
  return String(parseFloat(replacedValue));
};

Tools.percentageNumberFormatter = (value?: string) => `${value}%`;

Tools.percentageNumberParser = (value?: string) =>
  String(value?.replace('%', '') as unknown as number);

Tools.numberFormatter = (value?: string) => {
  if (!value) return '';
  const stringValue = value.toString();
  const parts = stringValue.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return parts.join(',');
};

Tools.numberParser = (value?: string) => {
  if (!value) return '';
  // Elimina todos los puntos de los miles y reemplaza la coma decimal con un punto
  const replacedValue = value.replace(/\./g, '').replace(',', '.');
  // Parsea el valor numérico
  return String(parseFloat(replacedValue));
};

Tools.removeProtocolHttpsFromString = (urlString: string): string => {
  return urlString.replace(/(^\w+:|^)\/\//, '');
};

Tools.storeFilters = (filter: any, page: string) => {
  let storedFilters: any = undefined;
  if (localStorage.getItem(EnumsValues.LocalStorageKeys.StoredFilters)) {
    storedFilters = JSON.parse(
      localStorage.getItem(
        EnumsValues.LocalStorageKeys.StoredFilters,
      ) as string,
    );
  }
  let filterToStore: any = undefined;
  if (storedFilters) {
    filterToStore = { ...storedFilters, [page]: { ...filter } };
  } else {
    filterToStore = { [page]: { ...filter } };
  }

  if (filterToStore) {
    localStorage.setItem(
      EnumsValues.LocalStorageKeys.StoredFilters,
      JSON.stringify(filterToStore),
    );
  }
};
Tools.resetStoredFilters = (page: string) => {
  let storedFilters: any = undefined;
  if (localStorage.getItem(EnumsValues.LocalStorageKeys.StoredFilters)) {
    storedFilters = JSON.parse(
      localStorage.getItem(
        EnumsValues.LocalStorageKeys.StoredFilters,
      ) as string,
    );
  }
  let filterToStore: any = undefined;
  if (storedFilters) {
    delete storedFilters[page];
    filterToStore = { ...storedFilters };
  }
  if (filterToStore && Object.keys(filterToStore).length) {
    localStorage.setItem(
      EnumsValues.LocalStorageKeys.StoredFilters,
      JSON.stringify(filterToStore),
    );
  } else {
    localStorage.removeItem(EnumsValues.LocalStorageKeys.StoredFilters);
  }
};
Tools.getFilterStored = (page: string) => {
  let storedFilters: any = undefined;
  if (
    localStorage.getItem(EnumsValues.LocalStorageKeys.StoredFilters) &&
    localStorage.getItem(EnumsValues.LocalStorageKeys.StoredFilters) !==
      'undefined'
  ) {
    storedFilters = JSON.parse(
      localStorage.getItem(
        EnumsValues.LocalStorageKeys.StoredFilters,
      ) as string,
    );
  }
  if (storedFilters) {
    return storedFilters[page];
  }
  return undefined;
};
Tools.storeOrders = (order: any, page: string) => {
  let storedOrder: any = undefined;
  if (localStorage.getItem(EnumsValues.LocalStorageKeys.StoredOrders)) {
    storedOrder = JSON.parse(
      localStorage.getItem(EnumsValues.LocalStorageKeys.StoredOrders) as string,
    );
  }
  let orderToStore: any = undefined;
  if (storedOrder) {
    orderToStore = { ...storedOrder, [page]: { ...order } };
  } else {
    orderToStore = { [page]: { ...order } };
  }
  localStorage.setItem(
    EnumsValues.LocalStorageKeys.StoredOrders,
    JSON.stringify(orderToStore),
  );
};
Tools.resetStoredOrders = (page: string) => {
  let storedOrder: any = undefined;
  if (localStorage.getItem(EnumsValues.LocalStorageKeys.StoredOrders)) {
    storedOrder = JSON.parse(
      localStorage.getItem(EnumsValues.LocalStorageKeys.StoredOrders) as string,
    );
  }
  let orderToStore: any = undefined;
  if (storedOrder) {
    delete storedOrder[page];
    orderToStore = { ...storedOrder };
  }
  if (orderToStore && Object.keys(orderToStore).length) {
    localStorage.setItem(
      EnumsValues.LocalStorageKeys.StoredOrders,
      JSON.stringify(orderToStore),
    );
  } else {
    localStorage.removeItem(EnumsValues.LocalStorageKeys.StoredOrders);
  }
};
Tools.getOrderStored = (page: string) => {
  let storedOrder: any = undefined;
  if (
    localStorage.getItem(EnumsValues.LocalStorageKeys.StoredOrders) &&
    localStorage.getItem(EnumsValues.LocalStorageKeys.StoredOrders) !==
      'undefined'
  ) {
    storedOrder = JSON.parse(
      localStorage.getItem(EnumsValues.LocalStorageKeys.StoredOrders) as string,
    );
  }
  if (storedOrder) {
    return storedOrder[page];
  }
  return undefined;
};

Tools.downloadFileFromBase64 = ({
  base64,
  mimetype,
  filename,
}: {
  base64: string;
  mimetype: string;
  filename: string;
}) => {
  const link = document.createElement('a');
  link.href = `data:${mimetype};base64,${base64}`;
  link.download = filename;
  link.click();
};

export { Tools };

Tools.formatTime = (time: number) => {
  const totalMinutes = Math.floor(time / 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return { hours, minutes };
};
