/* eslint-disable @typescript-eslint/no-unused-vars */
import { createRef, RefObject, useRef, useState } from 'react';
import {
  IVariantData,
  IVariantsOptionsCombinations,
} from '../interfaces/Product';
import { FormInstance } from 'antd';

const VARIANTS_DATA_DEFAULT_VALUE = [
  {
    id: -1,
    values: {
      name: '',
      options: [],
    },
  },
  {
    id: -2,
    values: {
      name: '',
      options: [],
    },
  },
];

const useProductsVariants = () => {
  const [
    variantConfigurationModalVisible,
    setVariantConfigurationModalVisible,
  ] = useState<boolean>(false);
  const variantsData = useRef<IVariantData[]>(VARIANTS_DATA_DEFAULT_VALUE);
  const [formIds, setFormIds] = useState<number[]>([-1, -2]);
  const formsRefs = useRef<RefObject<{ form: FormInstance }>[]>([]);

  formsRefs.current = formIds.map(
    (_formId, index) => (formsRefs.current[index] = createRef()),
  );

  const openVariantConfigurationModalVisible = () =>
    setVariantConfigurationModalVisible(true);
  const closeVariantConfigurationModalVisible = () =>
    setVariantConfigurationModalVisible(false);

  const addForm = () => {
    setFormIds([...formIds, formIds[formIds.length - 1] - 1]);

    variantsData.current.push({
      id: formIds[formIds.length - 1] - 1,
      values: {
        name: '',
        options: [],
      },
    });
  };

  const deleteForm = (id: number) => {
    if (formIds.length > 1) {
      setFormIds(formIds.filter((formId) => formId !== id));
    }
  };

  const handleFieldChange = (formId: number, values: any) => {
    const index = variantsData.current.findIndex((ele) => ele.id === formId);

    if (index !== -1) {
      variantsData.current[index].values = values;
    }
  };

  const combineOptions = (options: string[][]): string[][] => {
    if (options.length === 0) return [[]];
    const [first, ...rest] = options;
    const restCombinations = combineOptions(rest);
    return first.flatMap((item) =>
      restCombinations.map((combo) => [item, ...combo]),
    );
  };

  const getOptionsCombinations = (
    data: IVariantData[],
  ): IVariantsOptionsCombinations[] => {
    const combinations = combineOptions(data.map((ele) => ele.values.options));

    return combinations.map((combination, index) => ({
      id: (index + 1) * -1,
      combination,
    }));
  };

  return {
    variantConfigurationModalVisible,
    openVariantConfigurationModalVisible,
    closeVariantConfigurationModalVisible,
    variantsData,
    addForm,
    deleteForm,
    handleFieldChange,
    formIds,
    formsRefs,
    getOptionsCombinations,
  };
};
export default useProductsVariants;
