export interface ISupplier {
  id: number;
  name: string;
  fiscal_id_type: string;
  fiscal_id: string;
  product_address: string;
}

export interface IUpdateSupplierForm {
  name: string;
  fiscal_id_type: string;
  fiscal_id: string;
  product_address: string;
}
export const DEFAULT_ORDER_BY_SUPPLIERS = {
  direction: 'asc',
  field: 'name',
};
