/// <reference types="vite-plugin-svgr/client" />

import Icon from '@ant-design/icons';
import React from 'react';
import { ReactComponent as IconSvg } from '../../../assets/add_photo_aternate.svg';

export const AddPhotoAlternateIcon = (
  props: React.ComponentProps<typeof Icon>,
) => {
  return <Icon component={IconSvg} {...props} />;
};
