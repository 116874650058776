/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import './RadioButtonCard.less';
import { Radio } from 'antd';
import RadioButtonCardTitle from './RadioButtonCardTitle/RadioButtonCardTitle';
import RadioButtonCardDescription from './RadioButtonCardDescription/RadioButtonCardDescription';
interface IRadioButtonCardProps {
  value: any;
  checked?: boolean;
  children?: React.ReactElement | React.ReactElement[];
  disabled?: boolean;
}
export const RadioButtonCard = (props: IRadioButtonCardProps) => {
  const { children, checked } = props;
  return (
    <Radio
      {...props}
      className={`radio-button-card ${checked ? 'checked' : ''}`}
    >
      <div className="radio-button-card__container-text">{children}</div>
    </Radio>
  );
};

RadioButtonCard.Title = RadioButtonCardTitle;
RadioButtonCard.Description = RadioButtonCardDescription;
