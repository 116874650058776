import { ReactElement } from 'react';
import './RadioButtonCardDescription.less';

interface IRadioButtonCardDescriptionProps {
  children?: string | ReactElement | ReactElement[];
}
const RadioButtonCardDescription = ({
  children,
}: IRadioButtonCardDescriptionProps) => {
  return (
    <div>
      <span>{children}</span>
    </div>
  );
};
export default RadioButtonCardDescription;
