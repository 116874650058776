import './ProductManagementSection.less';
interface IProductManagementSectionProps {
  children?: JSX.Element | JSX.Element[];
  title: string;
}
const ProductManagementSection = ({
  children,
  title,
}: IProductManagementSectionProps) => {
  return (
    <div className="product-management-section">
      <div className="product-management-section__title">
        <span>{title}</span>
      </div>
      {children}
    </div>
  );
};
export default ProductManagementSection;
