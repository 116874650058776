import { ReactElement } from 'react';
import './RadioButtonCardTitle.less';

interface IRadioButtonCardTitleProps {
  children?: string | ReactElement | ReactElement[];
}
const RadioButtonCardTitle = ({ children }: IRadioButtonCardTitleProps) => {
  return (
    <div className="radio-button-card-title">
      <span className="radio-button-card-title__title">{children}</span>
    </div>
  );
};
export default RadioButtonCardTitle;
