import { message, UploadFile, UploadProps } from 'antd';
import { useContext, useState } from 'react';
import { Tools } from '../shared';
import { IPreviewImage } from '../interfaces/UploadImage';
import { EnumsValues } from '../enums/EnumsValues';
import { RcFile } from 'antd/lib/upload';
import { ContextApp } from '../contexts/ContextApp';

const UPLOAD_IMAGES_ACCEPT_IMAGES = [
  EnumsValues.MimeTypes.imagesJpeg,
  EnumsValues.MimeTypes.imagesPng,
  EnumsValues.MimeTypes.imagesJpg,
  EnumsValues.MimeTypes.imagesWebp,
];

const useUploadImage = () => {
  const { t } = useContext(ContextApp);
  const [fileList, setFileList] = useState<
    (UploadFile & { older?: boolean })[]
  >([]);
  const [preview, setPreview] = useState<IPreviewImage>({
    previewImage: '',
    previewTitle: '',
    previewOpen: false,
  });
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const [picturesToDelete, setPicturesToDelete] = useState<number[]>([]);
  const openPreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      Tools.getBase64WithCallback(file.originFileObj, (base64: any) => {
        file.preview = base64;
        setPreview({
          previewImage: file.url || (file.preview as string),
          previewOpen: true,
          previewTitle:
            file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
        });
      });
    } else if (file.url) {
      setPreview({
        previewImage: file.url,
        previewOpen: true,
        previewTitle:
          file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
      });
    }
  };

  const handleChange: UploadProps['onChange'] = ({
    file,
    fileList: newList,
  }) => {
    if (file.status === 'error') {
      setFileList(fileList.filter((ele) => ele.status !== 'uploading'));
      setLoadingImage(false);
      return;
    }
    if (file.status === 'uploading') {
      setLoadingImage(true);
    }
    if (file.status === 'done') {
      setLoadingImage(false);
    }
    setFileList(newList);
  };
  const handleRemove: UploadProps['onRemove'] = ({ uid }) => {
    setFileList(fileList.filter((ele) => ele.uid !== uid));
    if (!Number.isNaN(Number(uid))) {
      setPicturesToDelete([...picturesToDelete, Number(uid)]);
    }
  };
  const closePreview = () => {
    setPreview({ ...preview, previewOpen: false });
  };
  const addFilesToFileList = (files: (UploadFile & { older?: boolean })[]) => {
    setFileList(files);
  };

  const customRequestValidations = async ({
    file,
    onSuccess,
    onError,
  }: any) => {
    const fileRc = file as RcFile;
    const isJpgOrPng = UPLOAD_IMAGES_ACCEPT_IMAGES.some(
      (ele) => ele === fileRc.type,
    );

    if (!isJpgOrPng) {
      message.error(
        Tools.capitalize(
          t('validation.imageFormat', {
            replace: {
              typesSupported: `${UPLOAD_IMAGES_ACCEPT_IMAGES.map((ele) => {
                return ele.split('/')[1];
              })}`,
            },
          }),
        ),
      );
      const err = new Error();
      onError && onError(err);
      return;
    }
    const size = fileRc.size < EnumsValues.SystemLimits.MaxSizeOfFiles;
    if (!size) {
      message.error(Tools.capitalize(t('validation.imageTooBig')));
      const err = new Error();
      onError && onError(err);
      return;
    }
    onSuccess && onSuccess({});
  };
  return {
    handleRemove,
    handleChange,
    openPreview,
    closePreview,
    addFilesToFileList,
    loadingImage,
    preview,
    picturesToDelete,
    fileList,
    customRequestValidations,
  };
};
export default useUploadImage;
