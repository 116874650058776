import { useContext, useState } from 'react';
import { ContextApp } from '../contexts/ContextApp';
import {
  useCustomLazyQuery,
  useCustomMutation,
} from './apollo/ApolloCustomHooks';
import { Query } from '../services/graphql/query';
import {
  IFormCreateProduct,
  IFormUpdateProduct,
  IProduct,
} from '../interfaces/Product';
import { Mutation } from '../services/graphql/mutation';
import { useCustomMessage } from './useCustomMessage';
import { FormInstance } from 'antd';
import { Tools } from '../shared';
interface IUseProductArgs {
  defaultValueStateLoadingProduct?: boolean;
}
const useProduct = (args?: IUseProductArgs) => {
  const { selectedTenantId, t } = useContext(ContextApp);
  const [loadingCreateProduct, setLoadingCreateProduct] =
    useState<boolean>(false);
  const [loadingUpdateProduct, setLoadingUpdateProduct] =
    useState<boolean>(false);
  const [loadingProduct, setLoadingProduct] = useState<boolean>(
    !!args?.defaultValueStateLoadingProduct,
  );
  const [product, setProduct] = useState<IProduct>();

  const { showMessageError, messageSuccess } = useCustomMessage();

  const [createProductMutation] = useCustomMutation<{
    createProduct: IProduct;
  }>(Mutation.createProduct);

  const [updateProductMutation] = useCustomMutation<{
    updateProduct: IProduct;
  }>(Mutation.updateProduct);
  const [fetchProduct] = useCustomLazyQuery<{
    product: IProduct;
  }>(Query.product);

  const getProduct = async ({
    id,
    onErrorCallback,
  }: {
    id: number;
    onErrorCallback?: () => void;
  }) => {
    setLoadingProduct(true);
    try {
      const { data, error } = await fetchProduct({
        variables: {
          tenant_id: selectedTenantId,
          id: Number(id),
        },
      });
      if (error) throw error;
      if (data) setProduct(data.product);
    } catch (error: any) {
      const { networkError } = error;
      if (networkError) {
        showMessageError({
          context: 'useProduct.getProduct.1',
          error: networkError,
        });
      } else {
        showMessageError({
          context: 'useProduct.getProduct.2',
          error,
        });
      }
      onErrorCallback && onErrorCallback();
    }
    setLoadingProduct(false);
  };
  const createProduct = async ({
    values,
    formInstance,
    onSuccessCallback,
    onErrorCallback,
  }: {
    values: IFormCreateProduct;
    formInstance: FormInstance<any>;
    onSuccessCallback?: () => void;
    onErrorCallback?: () => void;
  }) => {
    try {
      setLoadingCreateProduct(true);
      await createProductMutation({
        variables: {
          input: {
            ...values,
            images: undefined,
          },
          tenant_id: selectedTenantId,
          files: values.images.fileList.map((ele) => ele.originFileObj),
        },
      });
      messageSuccess({
        context: 'useProduct.createProduct.1',
        message: Tools.capitalize(t('message.productCreated')),
      });
      formInstance.resetFields();
      onSuccessCallback && onSuccessCallback();
    } catch (error: any) {
      showMessageError({
        context: 'useProduct.createProduct.2',
        error: error.networkError,
      });
      onErrorCallback && onErrorCallback();
    }
    setLoadingCreateProduct(false);
  };

  const updateProduct = async ({
    values,
    onErrorCallback,
    onSuccessCallback,
  }: {
    values: IFormUpdateProduct;
    _formInstance?: FormInstance<any>;
    onSuccessCallback?: () => void;
    onErrorCallback?: () => void;
  }) => {
    try {
      setLoadingUpdateProduct(true);
      await updateProductMutation({
        variables: {
          input: {
            id: product?.id,
            ...values,
            weight: values.weight ? Number(values.weight) : undefined,
            height: values.height ? Number(values.height) : undefined,
            width: values.width ? Number(values.width) : undefined,
            length: values.length ? Number(values.length) : undefined,
            suggested_price: Number(values.suggested_price),
            cost: Number(values.cost),
            delete_pictures: values.delete_pictures?.length
              ? values.delete_pictures
              : undefined,
            images: undefined,
          },
          tenant_id: selectedTenantId,
          files: values.images
            ? values.images.fileList.map((ele) => ele.originFileObj)
            : undefined,
        },
      });
      messageSuccess({
        context: 'useProduct.updateProduct.1',
        message: Tools.capitalize(t('message.productUpdated')),
      });
      onSuccessCallback && onSuccessCallback();
    } catch (error: any) {
      showMessageError({
        context: 'useProduct.UpdateProduct.2',
        error: error.networkError,
      });
      onErrorCallback && onErrorCallback();
    }
    setLoadingUpdateProduct(false);
  };
  return {
    updateProduct,
    createProduct,
    getProduct,
    loadingCreateProduct,
    loadingUpdateProduct,
    loadingProduct,
    product,
  };
};
export default useProduct;
