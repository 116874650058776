import { useContext, useState } from 'react';
import { DEFAULT_ORDER_BY_SUPPLIERS, ISupplier } from '../interfaces/Supplier';
import { useCustomLazyQuery } from './apollo/ApolloCustomHooks';
import { Query } from '../services/graphql/query';
import { ContextApp } from '../contexts/ContextApp';

const useSupplier = () => {
  const { selectedTenantId } = useContext(ContextApp);
  const [loadingSupplier, setLoadingSupplier] = useState<boolean>(true);

  const [suppliers, setSuppliers] = useState<ISupplier[]>([]);
  const [fetchSuppliers] = useCustomLazyQuery<{
    suppliers: ISupplier[];
  }>(Query.suppliers);
  const getSuppliers = async () => {
    try {
      setLoadingSupplier(true);
      const { data, error } = await fetchSuppliers({
        variables: {
          tenant_id: selectedTenantId,
          orderBy: DEFAULT_ORDER_BY_SUPPLIERS,
        },
      });
      if (error) throw error;

      setSuppliers(data?.suppliers || []);
      setLoadingSupplier(false);
    } catch (error) {
      setLoadingSupplier(false);
      console.error(error);
    }
  };
  return {
    suppliers,
    getSuppliers,
    loadingSupplier,
  };
};
export default useSupplier;
