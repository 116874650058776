export interface ICategory {
  id: number;
  name: string;
  description?: string;
}

export const DEFAULT_ORDER_BY_CATEGORIES = {
  direction: 'asc',
  field: 'name',
};
