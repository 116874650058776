import { useContext } from 'react';
import { EnumsValues } from '../enums/EnumsValues';
import { ContextApp } from '../contexts/ContextApp';
import { Tools } from '../shared';

const useProductStatus = () => {
  const { t } = useContext(ContextApp);
  const productStatuses = [
    {
      value: EnumsValues.ProductStatusId.Active,
      label: Tools.capitalize(t('label.products.statusActive')),
    },
    {
      value: EnumsValues.ProductStatusId.Inactive,
      label: Tools.capitalize(t('label.products.statusInactive')),
    },
  ];
  return { productStatuses };
};
export default useProductStatus;
