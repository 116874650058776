export const CREATE_TAG = `
mutation createTag(
    $tenant_id: Int!,
    $input: CreateTagInput!,
  ){
    createTag(
      tenant_id: $tenant_id
      input: $input
    ){
      __typename
      ... on Tag{
        id
        name
        hex_color
      }
      ... on ResultError{
        status_code
        message
        message_translation_key
      }
    }
  }
`;

export const CREATE_TAG_ENTITY = `
mutation createTagEntity (
    $tenant_id: Int! 
    $input: CreateTagInput!
  ){
  createTagEntity (
    tenant_id: $tenant_id 
    input: $input
  ){
    __typename
    ...on TagEntity{
        id
        tag_id
        order_id
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const DELETE_ENTITY_TAG = `
mutation deleteEntityTag (
  $tenant_id: Int!
  $entityTagId: Int!
) {
  deleteEntityTag (
    tenant_id: $tenant_id
    entityTagId: $entityTagId
  ){
    ...on TagEntity {
      id
    }
    ...on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}`;
