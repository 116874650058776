import { useContext, useState } from 'react';
import {
  DEFAULT_ORDER_BY_CATEGORIES,
  ICategory,
} from '../interfaces/Categories';
import { Query } from '../services/graphql/query';
import { useCustomLazyQuery } from './apollo/ApolloCustomHooks';
import { ContextApp } from '../contexts/ContextApp';

const useCategory = () => {
  const { selectedTenantId } = useContext(ContextApp);
  const [loadingCategories, setLoadingCategories] = useState<boolean>(true);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [fetchCategories] = useCustomLazyQuery<{
    categories: ICategory[];
  }>(Query.categories);
  const getCategories = async () => {
    try {
      setLoadingCategories(true);
      const { data, error } = await fetchCategories({
        variables: {
          tenant_id: selectedTenantId,
          orderBy: DEFAULT_ORDER_BY_CATEGORIES,
        },
      });
      if (error) throw error;

      setCategories(data?.categories || []);
      setLoadingCategories(false);
    } catch (error) {
      setLoadingCategories(false);
      console.error(error);
    }
  };
  return {
    getCategories,
    loadingCategories,
    categories,
  };
};
export default useCategory;
