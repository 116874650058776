/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Spin,
  Upload,
} from 'antd';
import ProductManagementSection from './ProductManagementSection/ProductManagementSection';
import './ProductManagement.less';
import { IFormCreateProduct } from '../../interfaces/Product';
import { FormInstance } from 'antd/lib/form';
import { useContext, useEffect, useRef, useState } from 'react';

import { ContextApp } from '../../contexts/ContextApp';
import { Tools } from '../../shared';
import { useForm } from 'antd/lib/form/Form';
import { EnumsValues } from '../../enums/EnumsValues';

import { AddPhotoAlternateIcon } from '../../components/common/icons/AddPhotoAlternateIcon';
import { UploadFile } from 'antd/lib/upload';
import { ArrowBackIosIcon } from '../../components/common/icons/ArrowBackIosIcon';
import { NavContext } from '@ionic/react';
import { useParams } from 'react-router';
import useProductStatus from '../../hooks/useProductStatus';
import { RadioButtonCard } from '../../components/common/RadioButtonCard/RadioButtonCard';
import useUploadImage from '../../hooks/useUploadImage';
import ModalPreviewImage from '../../components/common/ModalPreviewImage/ModalPreviewImage';
import useProduct from '../../hooks/useProduct';
import useCategory from '../../hooks/useCategory';
import useSupplier from '../../hooks/useSupplier';
import useProductsVariants from '../../hooks/useProductsVariants';
import ProductsVariantsContainer from './ProductsVariants/ProductsVariantsContainer/ProductsVariantsContainer';
import ProductsVariantsForm from './ProductsVariants/ProductsVariantsForm/ProductsVariantsForm';

interface IProductManagementProps {
  editMode?: boolean;
}

const MAX_PRODUCTS_VARIANTS = 3;
const ProductManagement = ({ editMode }: IProductManagementProps) => {
  const { id } = useParams<any>();
  const { t } = useContext(ContextApp);
  const { goBack, navigate } = useContext(NavContext);

  const [valueRadioTypeProductSelector, setValueRadioTypeProductSelector] =
    useState<any>(
      EnumsValues.RadioButtonSelectorProductTypesValues.UniqueProduct,
    );
  const {
    closePreview,
    handleChange,
    handleRemove,
    loadingImage,
    openPreview,
    picturesToDelete,
    preview,
    addFilesToFileList,
    fileList,
    customRequestValidations,
  } = useUploadImage();
  const {
    createProduct,
    getProduct,
    loadingCreateProduct,
    loadingProduct,
    product: productEditForm,
    loadingUpdateProduct,
    updateProduct,
  } = useProduct({
    defaultValueStateLoadingProduct: !!editMode,
  });
  const {
    closeVariantConfigurationModalVisible,
    openVariantConfigurationModalVisible,
    variantConfigurationModalVisible,
    addForm,
    handleFieldChange,
    deleteForm,
    variantsData,
    formIds,
    formsRefs,
    getOptionsCombinations,
  } = useProductsVariants();
  const { categories, getCategories, loadingCategories } = useCategory();
  const { getSuppliers, loadingSupplier, suppliers } = useSupplier();
  const { productStatuses } = useProductStatus();
  const [formInstance] = useForm();

  const dataFetchedRef = useRef<boolean>(false);
  const stickyContainerRef = useRef<HTMLDivElement>(null);

  const onChangeRadioTypeProductSelector = (e: RadioChangeEvent) =>
    setValueRadioTypeProductSelector(e.target.value);

  const onFinish = (
    values: IFormCreateProduct,
    formInstance: FormInstance<any>,
  ) => {
    if (editMode) {
      updateProduct({
        values,
        onSuccessCallback: () => {
          getProduct({ id });
        },
      });
    } else {
      createProduct({
        values,
        formInstance,
        onSuccessCallback: () => {
          navigate('/app/products');
        },
      });
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getCategories();
    getSuppliers();
  }, []);

  useEffect(() => {
    if (editMode && productEditForm) {
      if (productEditForm.product_picture) {
        if (productEditForm.product_picture.length) {
          addFilesToFileList(
            productEditForm.product_picture.map((ele: any) => ({
              uid: ele.id,
              name: ele.filename,
              status: 'done',
              url: ele.url,
              older: true,
            })),
          );
        }
      }
    }
  }, [productEditForm]);

  useEffect(() => {
    const mainScrollableDiv = document.getElementById('main-scrollable-div');

    const setClassNameScrolledToStickyContainer = () => {
      if (mainScrollableDiv) {
        if (mainScrollableDiv.scrollTop > 100) {
          if (stickyContainerRef.current)
            stickyContainerRef.current.classList.add(
              'product-management__container-buttons-return-save-scrolled',
            );
        } else {
          if (stickyContainerRef.current)
            stickyContainerRef.current.classList.remove(
              'product-management__container-buttons-return-save-scrolled',
            );
        }
      }
    };

    if (mainScrollableDiv)
      mainScrollableDiv.addEventListener(
        'scroll',
        setClassNameScrolledToStickyContainer,
      );

    return () => {
      const mainScrollableDiv = document.getElementById('main-scrollable-div');
      if (mainScrollableDiv)
        mainScrollableDiv.addEventListener(
          'scroll',
          setClassNameScrolledToStickyContainer,
        );
    };
  }, []);

  useEffect(() => {
    if (id && editMode) {
      getProduct({
        id,
        onErrorCallback: () => {
          navigate('/app/products');
        },
      });
    }
  }, []);

  return (
    <div className="product-management">
      <div
        ref={stickyContainerRef}
        className="product-management__container-buttons-return-save"
      >
        <Button
          className="product-management__container-buttons-return-save__button-return"
          type="ghost"
          icon={<ArrowBackIosIcon />}
          onClick={() => {
            goBack();
          }}
        >
          {Tools.capitalize(t('action.back'))}
        </Button>
        <Button
          className="product-management__container-buttons-return-save__button-save"
          type="primary"
          onClick={() => {
            formInstance.submit();
          }}
          loading={loadingCreateProduct || loadingUpdateProduct}
        >
          {Tools.capitalize(t('action.save'))}
        </Button>
      </div>
      {loadingSupplier || loadingProduct || loadingCategories ? (
        <div className="product-management__container-loading">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Form
            layout="vertical"
            requiredMark="optional"
            initialValues={{
              ...productEditForm,
              supplier_id: suppliers.length ? suppliers[0].id : undefined,
              stock: !editMode ? productEditForm?.stock : undefined,
              status:
                productEditForm?.status || EnumsValues.ProductStatusId.Active,
              categories:
                editMode && productEditForm?.product_category?.length
                  ? productEditForm.product_category.map(
                      (ele: any) => ele.category_id,
                    )
                  : undefined,
            }}
            className="product-management__form"
            onFinish={(values) => {
              if (editMode) {
                const newImages: UploadFile[] = fileList.filter(
                  (ele) => !ele.older,
                );
                onFinish(
                  {
                    ...values,
                    images: newImages.length
                      ? {
                          file: newImages[0],
                          fileList: newImages,
                        }
                      : undefined,
                    delete_pictures: picturesToDelete,
                  },
                  formInstance,
                );
              } else {
                onFinish(values, formInstance);
              }
            }}
            form={formInstance}
          >
            <ProductManagementSection
              title={Tools.capitalize(
                t('label.productManagementSectionTitle.generalData'),
              )}
            >
              <Form.Item
                name="name"
                label={Tools.capitalize(t('label.products.name'))}
                rules={[
                  {
                    required: true,
                    message: Tools.capitalize(
                      t('validation.products.nameIsRequired'),
                    ),
                  },
                  {
                    max: EnumsValues.MaxLengthInputs.ProductName,
                    message: Tools.capitalize(
                      t('validation.products.productNameMaxChars', {
                        replace: {
                          max: EnumsValues.MaxLengthInputs.ProductName,
                        },
                      }),
                    ),
                  },
                ]}
              >
                <Input
                  placeholder={Tools.capitalize(t('placeholder.products.name'))}
                />
              </Form.Item>
              <Form.Item
                name="description"
                label={Tools.capitalize(t('label.products.description'))}
                rules={[
                  {
                    max: EnumsValues.MaxLengthInputs.ProductDescription,
                    message: Tools.capitalize(
                      t('validation.products.descriptionMaxChars', {
                        replace: {
                          max: EnumsValues.MaxLengthInputs.ProductDescription,
                        },
                      }),
                    ),
                  },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder={Tools.capitalize(
                    t('placeholder.products.description'),
                  )}
                />
              </Form.Item>
              <Form.Item
                name="categories"
                label={Tools.capitalize(t('label.products.categories'))}
                rules={[
                  {
                    required: true,
                    message: Tools.capitalize(
                      t('validation.products.categoriesIsRequired'),
                    ),
                  },
                ]}
              >
                <Select
                  loading={loadingCategories}
                  options={categories.map((category) => ({
                    label: category.name,
                    value: category.id,
                  }))}
                  mode="multiple"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  placeholder={Tools.capitalize(
                    t('placeholder.products.categories'),
                  )}
                  allowClear
                  showSearch
                  filterOption={(inputValue, option: any) =>
                    option?.label
                      ?.toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                />
              </Form.Item>
              <Form.Item
                name="supplier_id"
                label={Tools.capitalize(t('label.products.suppliers'))}
                rules={[
                  {
                    required: true,
                    message: Tools.capitalize(
                      t('validation.products.suppliersIsRequired'),
                    ),
                  },
                ]}
              >
                <Select
                  loading={loadingSupplier}
                  options={suppliers.map((supplier) => ({
                    label: supplier.name,
                    value: supplier.id,
                  }))}
                  // TODO: De momento solo hay un proveedor por lo que se lo mostrará al usuario pero no podrá seleccionar
                  disabled
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  placeholder={Tools.capitalize(
                    t('placeholder.products.suppliers'),
                  )}
                  allowClear
                  showSearch
                  filterOption={(inputValue, option: any) =>
                    option?.label
                      ?.toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                />
              </Form.Item>
            </ProductManagementSection>

            <ProductManagementSection
              title={Tools.capitalize(
                t('label.productManagementSectionTitle.measurementsData'),
              )}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="weight"
                    label={Tools.capitalize(t('label.products.weight'))}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value < 0) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                        message: Tools.capitalize(
                          t('validation.products.weightPositive'),
                        ),
                      },
                      {
                        validator: (_, value) => {
                          if (
                            value >
                            EnumsValues.InputNumberMaxValues.DimensionsInputs
                          ) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                        message: Tools.capitalize(
                          t('validation.products.dimensionsMaxValue', {
                            replace: {
                              max: Tools.numberFormatter(
                                String(
                                  EnumsValues.InputNumberMaxValues
                                    .DimensionsInputs,
                                ),
                              ),
                            },
                          }),
                        ),
                      },
                      {
                        required: true,
                        message: Tools.capitalize(
                          t('validation.products.weightRequired'),
                        ),
                      },
                    ]}
                  >
                    <InputNumber
                      formatter={Tools.numberFormatter}
                      parser={Tools.numberParser}
                      placeholder={Tools.capitalize(
                        t('placeholder.products.weight'),
                      )}
                    />
                  </Form.Item>

                  <Form.Item
                    name="height"
                    label={Tools.capitalize(t('label.products.height'))}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value < 0) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                        message: Tools.capitalize(
                          t('validation.products.heightPositive'),
                        ),
                      },
                      {
                        validator: (_, value) => {
                          if (
                            value >
                            EnumsValues.InputNumberMaxValues.DimensionsInputs
                          ) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                        message: Tools.capitalize(
                          t('validation.products.dimensionsMaxValue', {
                            replace: {
                              max: Tools.numberFormatter(
                                String(
                                  EnumsValues.InputNumberMaxValues
                                    .DimensionsInputs,
                                ),
                              ),
                            },
                          }),
                        ),
                      },
                      {
                        required: true,
                        message: Tools.capitalize(
                          t('validation.products.heightRequired'),
                        ),
                      },
                    ]}
                  >
                    <InputNumber
                      formatter={Tools.numberFormatter}
                      parser={Tools.numberParser}
                      placeholder={Tools.capitalize(
                        t('placeholder.products.height'),
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="width"
                    label={Tools.capitalize(t('label.products.width'))}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value < 0) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                        message: Tools.capitalize(
                          t('validation.products.widthPositive'),
                        ),
                      },
                      {
                        validator: (_, value) => {
                          if (
                            value >
                            EnumsValues.InputNumberMaxValues.DimensionsInputs
                          ) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                        message: Tools.capitalize(
                          t('validation.products.dimensionsMaxValue', {
                            replace: {
                              max: Tools.numberFormatter(
                                String(
                                  EnumsValues.InputNumberMaxValues
                                    .DimensionsInputs,
                                ),
                              ),
                            },
                          }),
                        ),
                      },
                      {
                        required: true,
                        message: Tools.capitalize(
                          t('validation.products.widthRequired'),
                        ),
                      },
                    ]}
                  >
                    <InputNumber
                      formatter={Tools.numberFormatter}
                      parser={Tools.numberParser}
                      placeholder={Tools.capitalize(
                        t('placeholder.products.width'),
                      )}
                    />
                  </Form.Item>

                  <Form.Item
                    name="length"
                    label={Tools.capitalize(t('label.products.length'))}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value < 0) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                        message: Tools.capitalize(
                          t('validation.products.lengthPositive'),
                        ),
                      },
                      {
                        validator: (_, value) => {
                          if (
                            value >
                            EnumsValues.InputNumberMaxValues.DimensionsInputs
                          ) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                        message: Tools.capitalize(
                          t('validation.products.dimensionsMaxValue', {
                            replace: {
                              max: Tools.numberFormatter(
                                String(
                                  EnumsValues.InputNumberMaxValues
                                    .DimensionsInputs,
                                ),
                              ),
                            },
                          }),
                        ),
                      },
                      {
                        required: true,
                        message: Tools.capitalize(
                          t('validation.products.lengthRequired'),
                        ),
                      },
                    ]}
                  >
                    <InputNumber
                      formatter={Tools.numberFormatter}
                      parser={Tools.numberParser}
                      placeholder={Tools.capitalize(
                        t('placeholder.products.length'),
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </ProductManagementSection>

            <ProductManagementSection
              title={Tools.capitalize(
                t('label.productManagementSectionTitle.specificationsData'),
              )}
            >
              <Space direction="vertical" style={{ display: 'flex' }}>
                <Radio.Group
                  style={{ width: '100%' }}
                  onChange={onChangeRadioTypeProductSelector}
                  value={valueRadioTypeProductSelector}
                >
                  <Row gutter={[16, 16]}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <RadioButtonCard
                        checked={
                          valueRadioTypeProductSelector ===
                          EnumsValues.RadioButtonSelectorProductTypesValues
                            .UniqueProduct
                        }
                        value={
                          EnumsValues.RadioButtonSelectorProductTypesValues
                            .UniqueProduct
                        }
                      >
                        <RadioButtonCard.Title>
                          {Tools.capitalize(
                            t('label.products.uniqueProductTitle'),
                          )}
                        </RadioButtonCard.Title>
                        <RadioButtonCard.Description>
                          {Tools.capitalize(
                            t('label.products.uniqueProductDescription'),
                          )}
                        </RadioButtonCard.Description>
                      </RadioButtonCard>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <RadioButtonCard
                        checked={
                          valueRadioTypeProductSelector ===
                          EnumsValues.RadioButtonSelectorProductTypesValues
                            .ProductsWithVariants
                        }
                        value={
                          EnumsValues.RadioButtonSelectorProductTypesValues
                            .ProductsWithVariants
                        }
                      >
                        <RadioButtonCard.Title>
                          {Tools.capitalize(
                            t('label.products.productWithVariantsTitle'),
                          )}
                        </RadioButtonCard.Title>
                        <RadioButtonCard.Description>
                          {Tools.capitalize(
                            t('label.products.productWithVariantsDescription'),
                          )}
                        </RadioButtonCard.Description>
                      </RadioButtonCard>
                    </Col>
                  </Row>
                </Radio.Group>
                {valueRadioTypeProductSelector ===
                EnumsValues.RadioButtonSelectorProductTypesValues
                  .UniqueProduct ? (
                  <>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name="cost"
                          label={Tools.capitalize(t('label.products.cost'))}
                          rules={[
                            {
                              required: true,
                              message: Tools.capitalize(
                                t('validation.products.costRequired'),
                              ),
                            },
                            {
                              validator: (_, value) => {
                                if (value < 0) {
                                  return Promise.reject();
                                }
                                return Promise.resolve();
                              },
                              message: Tools.capitalize(
                                t('validation.products.costPositive'),
                              ),
                            },
                            {
                              validator: (_, value) => {
                                const suggestedPrice = Number(
                                  formInstance?.getFieldValue(
                                    'suggested_price',
                                  ),
                                );
                                if (value > suggestedPrice) {
                                  return Promise.reject();
                                }
                                if (
                                  formInstance?.getFieldError('suggested_price')
                                    .length
                                ) {
                                  formInstance?.validateFields([
                                    'suggested_price',
                                  ]);
                                }
                                return Promise.resolve();
                              },
                              message: Tools.capitalize(
                                t(
                                  'validation.products.costMustBeMinorToSuggestedPrice',
                                ),
                              ),
                            },
                          ]}
                        >
                          <InputNumber
                            formatter={Tools.priceNumberFormatter}
                            parser={Tools.priceNumberParser}
                            placeholder={Tools.capitalize(
                              t('placeholder.products.cost'),
                            )}
                          />
                        </Form.Item>
                        <Form.Item
                          name="suggested_price"
                          label={Tools.capitalize(
                            t('label.products.suggestedPrice'),
                          )}
                          rules={[
                            {
                              required: true,
                              message: Tools.capitalize(
                                t('validation.products.suggestedPriceRequired'),
                              ),
                            },
                            {
                              validator: (_, value) => {
                                const cost = Number(
                                  formInstance?.getFieldValue('cost'),
                                );
                                if (!cost || value < cost) {
                                  return Promise.reject();
                                }
                                return Promise.resolve();
                              },
                              message: Tools.capitalize(
                                t(
                                  'validation.products.suggestedPriceGreaterThanCost',
                                ),
                              ),
                            },
                            {
                              validator: (_, value) => {
                                const cost = Number(
                                  formInstance?.getFieldValue('cost'),
                                );
                                if (value < cost) {
                                  return Promise.reject();
                                }
                                if (
                                  formInstance?.getFieldError('cost').length
                                ) {
                                  formInstance?.validateFields(['cost']);
                                }

                                return Promise.resolve();
                              },
                              message: Tools.capitalize(
                                t(
                                  'validation.products.suggestedPriceMustBeMajorToCostPrice',
                                ),
                              ),
                            },
                          ]}
                        >
                          <InputNumber
                            formatter={Tools.priceNumberFormatter}
                            parser={Tools.priceNumberParser}
                            placeholder={Tools.capitalize(
                              t('placeholder.products.suggestedPrice'),
                            )}
                          />
                        </Form.Item>
                        {!editMode && (
                          <>
                            <Form.Item
                              name="stock"
                              label={Tools.capitalize(
                                t('label.products.initialStock'),
                              )}
                              rules={[
                                {
                                  required: true,
                                  message: Tools.capitalize(
                                    t('validation.products.stockIsRequired'),
                                  ),
                                },
                                {
                                  validator: (_, value) => {
                                    if (value < 0) {
                                      return Promise.reject();
                                    }
                                    return Promise.resolve();
                                  },
                                  message: Tools.capitalize(
                                    t('validation.products.stockPositive'),
                                  ),
                                },
                              ]}
                            >
                              <InputNumber
                                formatter={Tools.numberFormatter}
                                parser={Tools.numberParser}
                                placeholder={Tools.capitalize(
                                  t('placeholder.products.stock'),
                                )}
                              />
                            </Form.Item>
                          </>
                        )}
                        <Form.Item
                          name="status"
                          label={Tools.capitalize(t('label.products.status'))}
                          rules={[
                            {
                              required: true,
                              message: Tools.capitalize(
                                t(
                                  'validation.products.productStatusIsRequired',
                                ),
                              ),
                            },
                          ]}
                        >
                          <Select
                            options={productStatuses.map((productStatus) => ({
                              label: productStatus.label,
                              value: productStatus.value,
                            }))}
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            placeholder={Tools.capitalize(
                              t('placeholder.products.status'),
                            )}
                            allowClear
                            showSearch
                            filterOption={(inputValue, option: any) =>
                              option?.label
                                ?.toLowerCase()
                                .indexOf(inputValue.toLowerCase()) >= 0
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          className="form-itemwithout-optional-mark"
                          name="images"
                          label={Tools.capitalize(t('label.products.images'))}
                          rules={[
                            {
                              validator: () => {
                                if (!fileList.length) {
                                  return Promise.reject();
                                }
                                return Promise.resolve();
                              },
                              message: Tools.capitalize(
                                t(
                                  'validation.products.productPictureIsRequired',
                                ),
                              ),
                            },
                          ]}
                        >
                          <Upload
                            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={openPreview}
                            onRemove={handleRemove}
                            onChange={handleChange}
                            customRequest={customRequestValidations}
                          >
                            {fileList.length >= 3 ? null : (
                              <>
                                {loadingImage ? (
                                  <>
                                    <Spin size="small" />
                                  </>
                                ) : (
                                  <>
                                    <button
                                      style={{
                                        border: 0,
                                        background: 'none',
                                        color: 'black',
                                      }}
                                      type="button"
                                    >
                                      <AddPhotoAlternateIcon />
                                      <div style={{ marginTop: 8 }}>
                                        {Tools.capitalize(
                                          t(
                                            'placeholder.products.productPicture',
                                          ),
                                        )}
                                      </div>
                                    </button>
                                  </>
                                )}
                              </>
                            )}
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => openVariantConfigurationModalVisible()}
                    >
                      {Tools.capitalize(t('action.configureVariants'))}
                    </Button>
                  </>
                )}
              </Space>
            </ProductManagementSection>
          </Form>
        </>
      )}
      <ModalPreviewImage preview={preview} onCancel={closePreview} />

      <Modal
        open={variantConfigurationModalVisible}
        onCancel={() => closeVariantConfigurationModalVisible()}
        title={Tools.capitalize(t('label.variantsConfigurationTitle'))}
        className="modal-product-variant"
        onOk={async () => {
          try {
            const validations = formsRefs.current.map((formRef) => {
              return formRef.current?.form.validateFields();
            });

            await Promise.all(validations);
            closeVariantConfigurationModalVisible();
            getOptionsCombinations(variantsData.current);
          } catch (error) {
            //intentional
          }
        }}
      >
        {formIds.map((id, index) => (
          <>
            <ProductsVariantsContainer
              key={id}
              variant={index + 1}
              hideDeleteVariantButton={formIds.length === 1 && index === 0}
              onDeleteVariant={() => deleteForm(id)}
            >
              <ProductsVariantsForm
                onValuesChange={(_, values) => {
                  handleFieldChange(id, values);
                }}
                refForm={formsRefs.current[index]}
              />
            </ProductsVariantsContainer>
          </>
        ))}
        {formIds.length < MAX_PRODUCTS_VARIANTS && (
          <>
            <Button
              onClick={addForm}
              className="modal-product-variant__button-add-variant"
            >
              {Tools.capitalize(t('action.addVariant'))}
            </Button>
          </>
        )}
      </Modal>
    </div>
  );
};
export default ProductManagement;
