/* eslint-disable @typescript-eslint/no-unused-vars */
import './ProductVariantsForm.less';
import { Form, FormInstance, Input } from 'antd';
import { Ref, useContext, useImperativeHandle } from 'react';
import { ContextApp } from '../../../../contexts/ContextApp';
import { Tools } from '../../../../shared';
import { AddCircleIcon } from '../../../../components/common/icons/AddCircleIcon';
import { DeleteIcon } from '../../../../components/common/icons/DeleteIcon';
import { useForm } from 'antd/lib/form/Form';

export interface IProducsVariantsFormProps {
  onValuesChange?: (changedValues: any, values: any) => void;
  refForm: Ref<{ form: FormInstance }>;
}

const ProductsVariantsForm = ({
  onValuesChange,
  refForm,
}: IProducsVariantsFormProps) => {
  const { t } = useContext(ContextApp);
  const [form] = useForm();
  useImperativeHandle(refForm, () => ({
    form,
  }));
  return (
    <Form
      layout="vertical"
      className="products-variants-form"
      onValuesChange={onValuesChange}
      form={form}
      requiredMark="optional"
    >
      <Form.Item
        className="products-variants-form__form-item"
        name={`name`}
        label={Tools.capitalize(t('label.products.variantName'))}
        rules={[
          {
            required: true,
            message: Tools.capitalize(
              t('validation.productsVariants.nameRequired'),
            ),
          },
        ]}
      >
        <Input
          placeholder={Tools.capitalize(t('placeholder.products.variantName'))}
        />
      </Form.Item>

      <Form.List
        name={'options'}
        initialValue={['']}
        rules={[
          {
            validator: async (_, options) => {
              if (!options || options.length < 2) {
                return Promise.reject();
              }
              return Promise.resolve();
            },
            message: Tools.capitalize(
              t('validation.productsVariants.atLeastOneOption'),
            ),
          },
        ]}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                label={
                  index === 0
                    ? Tools.capitalize(t('label.products.variantOptions'))
                    : ''
                }
                required={false}
                key={field.key}
                className="form-itemwithout-optional-mark products-variants-form__form-item"
              >
                <Form.Item
                  {...field}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: Tools.capitalize(
                        t('validation.productsVariants.optionRequired'),
                      ),
                    },
                  ]}
                  noStyle
                >
                  <Input
                    placeholder={
                      index === 0
                        ? Tools.capitalize(
                            t('placeholder.products.variantOptionExample'),
                          )
                        : Tools.capitalize(
                            t('placeholder.products.variantOption'),
                          )
                    }
                  />
                </Form.Item>
                {fields.length > 1 ? (
                  <DeleteIcon
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <button
              onClick={() => add()}
              className="products-variants-form__button-add-new-option"
            >
              <AddCircleIcon
                style={{
                  fontSize: '20px',
                  marginBottom: '2px',
                }}
              />{' '}
              {Tools.capitalize(t('action.addNewOption'))}
            </button>
          </>
        )}
      </Form.List>
    </Form>
  );
};
export default ProductsVariantsForm;
