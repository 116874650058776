import { Modal } from 'antd';
import { IPreviewImage } from '../../../interfaces/UploadImage';

interface IModalPreviewImage {
  preview: IPreviewImage;
  onCancel?: () => void;
}
const ModalPreviewImage = ({ preview, onCancel }: IModalPreviewImage) => {
  return (
    <Modal
      open={preview.previewOpen}
      title={preview.previewTitle}
      footer={null}
      centered
      onCancel={() => {
        onCancel && onCancel();
      }}
    >
      <img alt="example" style={{ width: '100%' }} src={preview.previewImage} />
    </Modal>
  );
};
export default ModalPreviewImage;
